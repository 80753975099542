<template>
  <div class="header">
    <div class="header-left">
      <!-- Breadcrumb 面包屑 -->
      <el-breadcrumb>
        <el-breadcrumb-item>
          <i class="el-icon-s-home" @click="collapseChage"></i> 后台
        </el-breadcrumb-item>
        <el-breadcrumb-item v-for="(v,i) in breadcrumbItem" :key="i">{{v.name}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="header-right">
      <div class="header-user-con">
        <!-- <div class="icon_wrap">
          <i class="el-icon-search"></i>
        </div>
        <div class="icon_wrap">
          <i class="el-icon-chat-dot-round"></i>
        </div>-->
        <!-- 全屏显示 -->
        <div class="icon_wrap btn-fullscreen" @click="handleFullScreen">
          <el-tooltip effect="dark" :content="fullscreen ? `取消全屏` : `全屏`" placement="bottom">
            <i class="el-icon-rank"></i>
          </el-tooltip>
        </div>
        <!-- 用户头像 -->
        <div class="user-avator">
          <img :src="headimgurl" />
        </div>
        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{ username }}
            <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="loginout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import LoginAjax from "@/utils/https/modules/Login.request.js";
import { getLocal } from "@/utils/utils";
import { getSession } from "@/utils/utils.js";
export default {
  data() {
    return {
      collapse: false,
      fullscreen: false,
      name: getLocal("nickname"), // 姓名
      username:'',
      breadcrumbItem: [],
      headimgurl:
        "https://j.weizan.cn/youxiang/images/kpi-home-img.png"
    };
  },
  computed: {
  },
  created() {
    this.$bus.$off("breadcrumbItem");
    this.$bus.$on("breadcrumbItem", item => {
      this.breadcrumbItem = item;
    });
    var kpiuser = getSession("admindetail");
    console.log(kpiuser);
    this.username = kpiuser.name;
  },
  methods: {
    // 用户名下拉菜单选择事件
    async handleCommand(command) {
      if (command === "loginout") {
        try {
          await LoginAjax.logout();
          this.$message.success("成功退出");
          sessionStorage.removeItem("yxtoken");
          sessionStorage.removeItem("admindetail");
          this.$router.push("/login");
        } catch (error) {
          this.$message.error(error);
        }
      }
    },
    // 侧边栏折叠
    collapseChage() {
      this.collapse = !this.collapse;
      this.$bus.$emit("collapse", this.collapse);
    },

    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    }
  }
};
</script>

<style lang="scss">
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  background-color: #fff;

  .header-left {
    display: flex;
    flex-direction: row;
    height: 50px;
    line-height: 50px;
    .collapse-btn {
      padding: 0 21px;
      cursor: pointer;
      font-size: 24px;
    }
    .el-breadcrumb {
      display: flex;
      align-items: center;
      padding-left: 20px;
      i {
        cursor: pointer;
      }
    }
  }

  .header-right {
    padding-right: 50px;
    .header-user-con {
      display: flex;
      align-items: center;
      height: 60px;
      .icon_wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        margin: 0 10px;
      }
      .btn-fullscreen {
        i {
          transform: rotate(45deg);
        }
      }
    }
    .user-name {
      margin-left: 10px;
      .el-dropdown-link {
        color: #606266;
        cursor: pointer;
      }
    }
    .user-avator img {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

.scan-app-con {
  text-align: center;
}
</style>
