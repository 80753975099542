var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header" }, [
    _c(
      "div",
      { staticClass: "header-left" },
      [
        _c(
          "el-breadcrumb",
          [
            _c("el-breadcrumb-item", [
              _c("i", {
                staticClass: "el-icon-s-home",
                on: { click: _vm.collapseChage }
              }),
              _vm._v(" 后台 ")
            ]),
            _vm._l(_vm.breadcrumbItem, function(v, i) {
              return _c("el-breadcrumb-item", { key: i }, [
                _vm._v(_vm._s(v.name))
              ])
            })
          ],
          2
        )
      ],
      1
    ),
    _c("div", { staticClass: "header-right" }, [
      _c(
        "div",
        { staticClass: "header-user-con" },
        [
          _c(
            "div",
            {
              staticClass: "icon_wrap btn-fullscreen",
              on: { click: _vm.handleFullScreen }
            },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "dark",
                    content: _vm.fullscreen ? "取消全屏" : "全屏",
                    placement: "bottom"
                  }
                },
                [_c("i", { staticClass: "el-icon-rank" })]
              )
            ],
            1
          ),
          _c("div", { staticClass: "user-avator" }, [
            _c("img", { attrs: { src: _vm.headimgurl } })
          ]),
          _c(
            "el-dropdown",
            {
              staticClass: "user-name",
              attrs: { trigger: "click" },
              on: { command: _vm.handleCommand }
            },
            [
              _c("span", { staticClass: "el-dropdown-link" }, [
                _vm._v(" " + _vm._s(_vm.username) + " "),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "loginout" } }, [
                    _vm._v("退出")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }